<template>
  <div class="flex flex-col">
    <div class="text-center max-w-4xl mx-auto">
      <h1 class="text-3xl font-bold">{{ $t("policy_page.advice_and_support") }}</h1>
      <p class="mt-5 text-xl">{{ $t("policy_page.data_1") }}</p>
      <p class="mt-4 text-sm text-gray-400">{{ $t("policy_page.data_2") }}</p>
      <div class="relative max-w-xl mx-auto mt-10">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input v-model.trim="searchQuery" type="search" class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none" placeholder="" v-on:keyup.enter="getSearchResults()" />
        <button @click="getSearchResults()" class="text-white absolute right-2.5 bottom-2.5 bg-primary-500 font-medium rounded-lg text-sm px-4 py-2">{{ $t("policy_page.search") }}</button>
      </div>
    </div>

    <div class="flex-col mt-12">
      <div v-if="searchedQuery" class="text-center mx-auto">
        <span>{{ searchResults.length }} {{ $t("policy_page.results_found") }}</span>
        <button @click="clearSearch()" class="btn-secondary-outline ml-6">
          {{ $t("policy_page.clear") }}
          <BaseIcon icon="times" class="ml-1" />
        </button>
      </div>
      <div v-for="p in searchResults" :key="p._id" class="w-full flex-col my-4 mx-6">
        <router-link target="_blank" :to="'/policy-routine/p/' + p._id" class="link text-lg">{{ p.title }}</router-link>
        <div class="max-w-4xl">{{ getPostDescriptionSnippet(p.description) }}</div>
      </div>
    </div>

    <div class="flex flex-wrap mt-10" v-if="searchedQuery == ''">
      <div v-for="c in policyCategories" :key="c._id" class="w-full sm:w-1/2 md:w-1/2 xl:w-1/3 flex flex-col p-4">
        <div class="flex-1 p-6 border border-gray-300 hover:shadow-lg rounded-lg transition-shadow delay-100 ease-in-out duration-700">
          <h3 class="text-2xl font-bold">
            {{ c.name }}
            <button v-if="user.role == 'admin'" class="ml-4" @click="editPolicyCategory(c)">
              <BaseIcon icon="pencil-alt" class="text-primary-500 text-md" />
            </button>
          </h3>
          <pre class="font-sans whitespace-pre-wrap mt-4">{{ c.description }}</pre>
          <div class="mt-6">
            <template v-for="p in policyPosts">
              <div v-if="p.parentId == c._id" v-bind:key="p._id" class="text-primary-500 text-lg font-semibold">
                <router-link :to="'/policy-routine/p/' + p._id" tag="span" class="hover:underline cursor-pointer">{{ p.title }}</router-link>
                <button v-if="user.role == 'admin'" class="ml-4" @click="editPolicyPost(p._id)">
                  <BaseIcon icon="pencil-alt" class="text-primary-500 text-sm" />
                </button>
                <BaseIcon v-else icon="long-arrow-alt-right" class="ml-3" />
              </div>
            </template>
            <div class="text-primary-500 text-lg font-semibold mt-2">
              <button v-if="user.role == 'admin'" class="" @click="newPolicyPost(c._id)">
                <BaseIcon icon="plus" class="text-primary-500 text-sm" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user.role == 'admin'" class="w-full sm:w-1/2 md:w-1/2 xl:w-1/3 flex flex-col p-4">
        <div class="flex justify-center p-6 border border-gray-100 rounded-lg">
          <button class="btn-blue-outline" @click="newPolicyCategory()">{{ $t("policy_page.new") }} <BaseIcon icon="plus" class="ml-2" /></button>
        </div>
      </div>
    </div>

    <PolicyCategory ref="policyCategory" :categoryInfo="policyCategory" @updatePolicyCategory="getPolicyRoutines()" />
    <PolicyPost ref="policyPost" :policyCategoryId="policyCategoryId" :policyPostId="policyPostId" @updatePolicyPost="getPolicyRoutines()" />
  </div>
</template>

<script>
import PolicyCategory from "./policy_category.vue";
import PolicyPost from "./policy_post_update.vue";

export default {
  name: "PolicyRoutines",
  title() {
    return `Policy & Rutiner (Systematiskt arbetsmiljöarbete) | SecurCloud`;
  },
  components: {
    PolicyCategory,
    PolicyPost,
  },

  data() {
    return {
      policyCategories: [],
      policyPosts: [],
      policyCategory: null,
      policyCategoryId: null,
      policyPostId: null,
      searchQuery: "",
      searchedQuery: "",
      searchResults: [],
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    async getPolicyRoutines() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/policy-info`);
        this.policyCategories = response.data.policy_categories;
        this.policyPosts = response.data.policy_posts;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getSearchResults() {
      try {
        if (this.searchQuery != "") {
          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/policy-post/search`, { searchQuery: this.searchQuery });
          this.searchResults = response.data.search_results;
          this.searchedQuery = response.data.searchQuery;
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    clearSearch() {
      this.searchQuery = "";
      this.searchedQuery = "";
      this.searchResults = [];
    },

    getPostDescriptionSnippet(description) {
      let descText = description
        .replace(/<[^>]*>/g, " ")
        .replace(/\s+/g, " ")
        .trim();
      let descTrim = descText.substring(0, 200);

      if (descTrim != "" && descTrim.length < descText.length) descTrim += "...";

      return descTrim;
    },

    newPolicyCategory() {
      this.policyCategory = null;
      this.$refs.policyCategory.setupNewForm();
      this.$modal.show("policy-category");
    },

    editPolicyCategory(policyCategory) {
      this.policyCategory = policyCategory;
      this.$modal.show("policy-category");
    },

    newPolicyPost(policyCategoryId) {
      this.policyCategoryId = policyCategoryId;
      this.policyPostId = null;
      this.$refs.policyPost.setupNewPostForm();
      this.$modal.show("policy-post");
    },

    editPolicyPost(policyPostId) {
      if (this.policyPostId == policyPostId) this.$refs.policyPost.setupUpdatePostForm();
      this.policyPostId = policyPostId;
      this.$modal.show("policy-post");
    },
  },

  created() {
    this.getPolicyRoutines();
    this.setPageTitle(`Policy & Rutiner (Systematiskt arbetsmiljöarbete)`, "policy_routines");
  },
};
</script>
