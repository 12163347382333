<template>
  <modal name="policy-post" class="modal-inner" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetForm">
    <LoadingSpinner :isLoading="isLoading" />
    <span class="close-button" @click="hide('policy-post')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      Policyinlägg
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formPolicyPostInfo">
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <BaseInput v-model="updateInfo.title" type="text" field_name="Title" rules="required" />
          </div>
          <div class="w-full px-2 py-2">
            <TextEditor v-model="updateInfo.description" />
          </div>
        </div>
        <div v-if="updateInfo.file_links.length > 0" class="flex flex-wrap mt-4">
          <div class="w-full px-2 py-2 text-gray-600 text-sm font-bold font-serif">PDF</div>
          <div v-for="(f, i) in updateInfo.file_links" :key="i" class="w-full px-2 py-2">
            <a class="link" :href="f.link" target="_blank">{{ f.name }}</a>
            <button class="btn-red-outline ml-4" @click="removeFile(i)">
              <BaseIcon icon="trash" />
            </button>
          </div>
        </div>
        <div v-else class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2 py-2">
            <label class="text-gray-600 text-sm font-bold pb-2 font-serif">PDF</label>
            <input type="file" accept="application/pdf" ref="uploadFile" @change="fileChange()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
          </div>
          <div v-if="updateFile" class="w-full md:w-1/2 px-2 py-2">
            <BaseInput v-model="fileName" type="text" field_name="Filnamn" rules="required" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('policy-post')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updatePolicyPostInfo()">
          <span v-if="updateInfo && updateInfo._id">Spara<BaseIcon icon="save" class="ml-1"/></span>
          <span v-else>Skapa<BaseIcon icon="plus" class="ml-1"/></span>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 1100;
import TextEditor from "@/components/text_editor/editor.vue";

export default {
  components: {
    TextEditor,
  },
  props: {
    policyCategoryId: {
      required: true,
      default: null,
    },
    policyPostId: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      updateInfo: {
        title: "",
        description: "",
        file_links: [],
        parentId: "",
      },
      updateFile: null,
      fileName: "",
      fileRemoved: 0,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  watch: {
    policyPostId(newVal) {
      if (newVal) this.setupUpdatePostForm();
      else this.setupNewPostForm();
    },
  },
  methods: {
    setupUpdatePostForm() {
      this.getPolicyPost();
      this.updateFile = null;
      this.fileName = "";
      this.fileRemoved = 0;
    },

    setupNewPostForm() {
      this.updateInfo = {
        title: "",
        description: "",
        file_links: [],
        parentId: "",
      };
      this.updateFile = null;
      this.fileName = "";
      this.fileRemoved = 0;
    },

    async getPolicyPost() {
      try {
        this.isLoading = true;

        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/policy-post/${this.policyPostId}`);
        this.updateInfo = response.data.policy_post;

        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
        this.isLoading = false;
      }
    },

    updatePolicyPostInfo() {
      this.$refs.formPolicyPostInfo.validate().then((success) => {
        if (!success) {
          return;
        }

        this.isLoading = true;

        let headers = {
          "Content-Type": "multipart/form-data",
          "X-Access-Token": this.$store.state.token,
        };

        let formData = new FormData();

        if (this.updateInfo._id) {
          formData.append("title", this.updateInfo.title);
          formData.append("description", this.updateInfo.description);
          formData.append("fileRemoved", this.fileRemoved);

          if (this.updateFile) {
            formData.append("pdf", this.updateFile);
            formData.append("fileName", this.fileName);
          }

          this.axios
            .put(`${process.env.VUE_APP_SERVER_URL}/policy-post/${this.updateInfo._id}`, formData, headers)
            .then(() => {
              // eslint-disable-line
              this.isLoading = false;
              this.fileRemoved = 0;
              this.$emit("updatePolicyPost");
              this.$modal.hide("policy-post");
            })
            .catch((error) => {
              this.handleError(error);
              this.isLoading = false;
            });
        } else {
          formData.append("title", this.updateInfo.title);
          formData.append("description", this.updateInfo.description);
          formData.append("parentId", this.policyCategoryId);

          if (this.updateFile) {
            formData.append("pdf", this.updateFile);
            formData.append("fileName", this.fileName);
          }

          this.axios
            .post(`${process.env.VUE_APP_SERVER_URL}/policy-post`, formData, headers)
            .then(() => {
              // eslint-disable-line
              this.isLoading = false;
              this.$emit("updatePolicyPost");
              this.$modal.hide("policy-post");
            })
            .catch((error) => {
              this.handleError(error);
              this.isLoading = false;
            });
        }
      });
    },

    fileChange() {
      if (this.$refs.uploadFile.files.length > 0) {
        this.updateFile = this.$refs.uploadFile.files[0];
        this.fileName = this.removeExtension(this.updateFile.name);
      } else {
        this.updateFile = null;
        this.fileName = "";
      }
    },

    removeExtension(filename) {
      return filename.substring(0, filename.lastIndexOf(".")) || filename;
    },

    removeFile(i) {
      this.updateInfo.file_links.splice(i, 1);
      this.fileRemoved = 1;
    },

    resetForm() {
      this.$refs.formPolicyPostInfo.reset();
      this.updateFile = null;
      this.fileName = "";
      this.fileRemoved = 0;
    },

    hide(id) {
      this.resetForm();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
