<template>
  <modal name="policy-category" class="modal-inner" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetForm">
    <span class="close-button" @click="hide('policy-category')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span v-if="categoryInfo && categoryInfo._id">Ändra</span>
      <span v-else>Ny kategori</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formPolicyCategory">
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <BaseInput v-model="updateInfo.name" type="text" field_name="Namn" rules="required" />
          </div>
          <div class="w-full px-2 py-2">
            <BaseTextarea rows="3" v-model="updateInfo.description" field_name="Beskrivning" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('policy-category')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="px-3 py-2 float-right" v-bind:class="categoryInfo && categoryInfo._id ? 'btn-green' : 'btn-blue'" @click="updatePolicyCategory()">
          <span v-if="categoryInfo && categoryInfo._id">Spara<BaseIcon icon="save" class="ml-1"/></span>
          <span v-else>Skapa<BaseIcon icon="plus" class="ml-1"/></span>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 500;

export default {
  props: {
    categoryInfo: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      updateInfo: {
        name: "",
        description: "",
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  watch: {
    categoryInfo(newVal) {
      if (newVal) this.updateInfo = _.cloneDeep(newVal);
      else this.setupNewForm();
    },
  },
  methods: {
    setupNewForm() {
      this.updateInfo = {
        name: "",
        description: "",
      };
    },

    updatePolicyCategory() {
      this.$refs.formPolicyCategory.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.updateInfo._id) {
          let data = {
            name: this.updateInfo.name,
            description: this.updateInfo.description,
          };

          this.axios
            .put(`${process.env.VUE_APP_SERVER_URL}/policy-category/${this.updateInfo._id}`, data)
            .then(() => {
              // eslint-disable-line
              this.$emit("updatePolicyCategory");
              this.$modal.hide("policy-category");
            })
            .catch((error) => {
              this.handleError(error);
            });
        } else {
          this.axios
            .post(`${process.env.VUE_APP_SERVER_URL}/policy-category`, this.updateInfo)
            .then(() => {
              // eslint-disable-line
              this.$emit("updatePolicyCategory");
              this.$modal.hide("policy-category");
            })
            .catch((error) => {
              this.handleError(error);
            });
        }
      });
    },

    resetForm() {
      this.$refs.formPolicyCategory.reset();
    },

    hide(id) {
      this.resetForm();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
